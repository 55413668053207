import React from "react"
import Header from "../components/header"
import TransparentLink from "../components/transparentlink"
import { makeStyles } from "@material-ui/core/styles"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image";
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => ({
  backgroundRoot: {
    position:'fixed',
    width:'100%',
    height:'100%',
    top:'0px',
    left:'0px',
    zIndex:-1,
    userSelect: 'none',
    display: 'flex',
    flexDirection:'column',
  },
  backgroundImg: {
    flexGrow:1
  },
  foregroundRoot: {
    width:'100%',
    height:'100vh',
    display: 'flex',
    flexDirection:'column',
  },
  body: {
    display: 'flex',
    flexGrow:1,
  },
  verticalGrid: {
    display: 'flex',
    flexDirection:'column',
    flexGrow:1,
  },
  verticalGridTop: {
    height:'62%',
    display: 'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignContent:'center',
  },
  topSignature:{
    display: 'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignContent:'center',
    width:'80%',
    maxWidth:'1000px',
  },
  verticalGridBottom: {
    height:'38%',
    display: 'flex',
    flexDirection:'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
  },
  bottomElement: {
    flexGrow: 1,
    borderColor: 'white',
    borderTopStyle: 'solid',
    borderWidth: '2px',
    borderLeftStyle: 'hidden',
    borderRightStyle: 'hidden',
  },
  nonFirstElement:{
    borderLeftStyle: 'solid',
  }
}));

export default function Home() {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          pages {
            name
            link
            short
          }
        }
      }
      background: file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      signature: file(relativePath: { eq: "signature.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const links = data.site.siteMetadata.pages;

  return(
    <React.Fragment>
      <div className={classes.backgroundRoot}>
        <Header spacerOnly={true}/>
        <Img className={classes.backgroundImg} draggable={false} fluid={data.background.childImageSharp.fluid} />
      </div>
      <div className={classes.foregroundRoot}>
        <Header/>
        <div className={classes.body}>
          <div className={classes.verticalGrid}>
            <div className={classes.verticalGridTop}>
              <div className={classes.topSignature}>
                <Img draggable={false} fluid={data.signature.childImageSharp.fluid} />
              </div>

            </div>
            <div className={classes.verticalGridBottom}>
              {
                links.map((item, index) => {
                  const first = index === 0;
                  let className = classes.bottomElement;
                  if (!first){
                    className += " " + classes.nonFirstElement;
                  }
                  return <div key={index} className={className}>
                    <TransparentLink to={item.link}>
                      <Typography
                        key="name"
                        align="center"
                        variant="h5"
                        color="inherit"
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        key="short"
                        align="center"
                        variant="body1"
                        color="inherit"
                      >
                        {item.short}
                      </Typography>
                    </TransparentLink>
                  </div>;
                })
              }
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>
  );
}
