import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby"

const useStyles = makeStyles((theme) => ({
  link:{
    '&:hover, &:focus, &$focusVisible': {
      '& $backdrop': {
        opacity: 0.6,
      },
    },
  },
  linkBody:{
    width: '100%',
    height: '100%',

    // center the content of the link
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    // We position the backdrop 'absolute', to make it relative to the
    // container, we have to position this one too. We don't actually
    // want to modify the position, so we set it to 'relative' and dont'
    // change it.
    position: 'relative',
  },
  linkActive: {
    '& $backdrop': {
      opacity: 0.6,
    },
  },
  backdrop:{
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.0,
    transition: theme.transitions.create('opacity'),
  },
  content:{
    // place the content before the backdrop
    zIndex:1,
  },
}));


export default function TransparentLink(props) {
  const classes = useStyles();
  return (
    <Link to={props.to} className={classes.link} activeClassName={classes.linkActive} style={{ color: `#FFFFFF`, textShadow: `none`, backgroundImage: `none`, textDecoration: 'none'}}>
      <div className={classes.linkBody}>
        <div className={classes.backdrop} />
        <div className={classes.content}>
          {props.children}
        </div>
      </div>
    </Link>
  );
}